import ApiService from '@/services/ApiService';

class WebsitesService {
  static async getWebsites() {
    const composedURL = `/api3/websites`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getWebsiteData(websiteId) {
    const composedURL = `/api3/websites/${websiteId}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getWebsiteAssociations() {
    const composedURL = `/api3/websites/associations`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async createWebsite(name) {
    const composedURL = `/api3/websites?domain_name=${encodeURIComponent(
      name
    )}`;
    const response = await ApiService.post(composedURL);
    return response;
  }

  static async deleteWebsite(websiteId) {
    const composedURL = `/api3/websites/${websiteId}/delete`;
    const response = await ApiService.delete(composedURL);
    return response;
  }

  static async updateWebsite(websiteId, requestBody) {
    const composedURL = `/api3/websites/${websiteId}/update`;
    const response = await ApiService.patch(composedURL, requestBody);
    return response;
  }

  static async uploadImage(websiteId, imageKey, imageFile) {
    const composedURL = `/api3/websites/${websiteId}/image_upload`;
    const formData = new FormData();
    formData.append(imageKey, imageFile);
    const response = await ApiService.put(
      composedURL,
      formData,
      undefined,
      true
    );
    return response;
  }

  static async deleteImage(websiteId, requestBody) {
    const composedURL = `/api3/websites/${websiteId}/set_image_as_none`;
    const response = await ApiService.delete(composedURL, requestBody);
    return response;
  }

  static async publishWebsite(websiteId) {
    const composedURL = `/api3/websites/${websiteId}/publish`;
    const response = await ApiService.post(composedURL);
    return response;
  }

  static async setAsMainWebsite(websiteId) {
    const composedURL = `/api3/websites/${websiteId}/set_as_main`;
    const response = await ApiService.post(composedURL);
    return response;
  }

  static async saveAliasWebsite(websiteId, aliasText) {
    const composedURL = `/api3/websites/${websiteId}/save_alias`;
    const response = await ApiService.post(composedURL, {
      alias_text: aliasText,
    });
    return response;
  }

  static async removeAliasWebsite(websiteId, aliasId) {
    const composedURL = `/api3/websites/${websiteId}/remove_alias`;
    const response = await ApiService.delete(composedURL, {
      alias_id: aliasId,
    });
    return response;
  }

  static async getIntercomData() {
    const composedURL = `/api3/core/intercom`;
    const response = await ApiService.get(composedURL);
    return response;
  }
}

export default WebsitesService;
