import { createSlice } from '@reduxjs/toolkit';
import {
  getInstagramDevelopmentData,
  getInstagramPropertyData,
  postInstagramPublication,
} from './services';
import initialState from './initialState';
import {
  checkIfIsHashtagsError,
  checkIfIsMentionsError,
  getCountStatus,
  getErrorTextKey,
} from '@/components/InstagramPost/utils';
import sanitizeHtml from '@/components/WysiwygEditor/utils/sanitizeHtml';
import convertHtmlToText from '@/components/WysiwygEditor/utils/convertHtmlToText';

const MAX_STEPS = 2;

export const InstagramPostSlice = createSlice({
  name: 'InstagramPost',
  initialState,
  reducers: {
    setInstagramPostData(state, action) {
      const { key, subkey, value } = action.payload;
      if (!key) return;

      if (subkey) {
        state[key][subkey] = value;
        return;
      }

      state[key] = value;

      if (key === 'step') {
        state.previewIndex = 0;
      }
    },
    setInstagramPostDescription(state, action) {
      const newDescription = action.payload;
      const countStatus = getCountStatus(newDescription);
      const isMentionsError = checkIfIsMentionsError(newDescription);
      const isHashtagsError = checkIfIsHashtagsError(newDescription);
      const isError =
        countStatus === 'error' || isMentionsError || isHashtagsError;
      const errorTextKey = getErrorTextKey(
        countStatus,
        isMentionsError,
        isHashtagsError
      );

      const description = {
        value: newDescription,
        errorTextKey,
        showErrorBanner: !isError ? false : state.showErrorBanner,
        error: isError,
      };

      Object.assign(state, { description });

      InstagramPostSlice.caseReducers.validateInstagramPostStep(state, {
        type: 'InstagramPost/validateInstagramPostStep',
      });
    },
    setInstagramPostItems(state, action) {
      const items = action.payload;
      const checkedItems = items.filter((item) => item.checked);

      state.entity = { ...state.entity, items, checkedItems };

      InstagramPostSlice.caseReducers.validateInstagramPostStep(state, {
        type: 'InstagramPost/validateInstagramPostStep',
      });
    },
    validateInstagramPostStep(state) {
      const canMoveForward =
        !state.entity.error &&
        !state.description.error &&
        state.entity.checkedItems.length > 0;

      state.canMoveToNextStep = canMoveForward;
    },
    handleInstagramPostPrevStep(state) {
      if (state.step > 1) {
        state.step -= 1;
      } else {
        window.history.back();
      }
    },
    handleInstagramPostNextStep(state) {
      if (state.description.error) {
        state.description.showErrorBanner = true;
        return;
      }

      if (state.step < MAX_STEPS && state.canMoveToNextStep) {
        state.step += 1;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInstagramPropertyData.pending, (state) => {
      state.entity = {
        ...state.entity,
        error: false,
        loading: true,
        images: null,
      };
    });
    builder.addCase(getInstagramPropertyData.fulfilled, (state, action) => {
      const { pictures } = action.payload.data;
      const images = [];

      if (pictures.front_cover_image?.url) {
        images.push(pictures.front_cover_image.url);
      }

      if (pictures.images?.length > 0) {
        images.push(...pictures.images);
      }

      if (pictures.blueprints?.length > 0) {
        images.push(...pictures.blueprints);
      }

      state.entity = {
        ...state.entity,
        loading: false,
        images,
        error: false,
        type: 'property',
        id: action.payload.data.id,
      };

      // the property description comes as html
      const sanitizedDescription = sanitizeHtml(
        action.payload.data.description
      );
      const textDescription = convertHtmlToText(sanitizedDescription);

      InstagramPostSlice.caseReducers.setInstagramPostDescription(state, {
        type: 'InstagramPost/setInstagramPostDescription',
        payload: textDescription,
      });
    });
    builder.addCase(getInstagramPropertyData.rejected, (state) => {
      state.entity = { ...state.entity, error: true, loading: false };
    });
    builder.addCase(getInstagramDevelopmentData.pending, (state) => {
      state.entity = {
        ...state.entity,
        error: false,
        loading: true,
        images: null,
      };
    });
    builder.addCase(getInstagramDevelopmentData.fulfilled, (state, action) => {
      const { pictures } = action.payload.data;
      const images = [];

      if (pictures.front_cover_image?.url) {
        images.push(pictures.front_cover_image.url);
      }

      if (pictures.images?.length > 0) {
        images.push(...pictures.images);
      }

      if (pictures.blueprints?.length > 0) {
        images.push(...pictures.blueprints);
      }

      state.entity = {
        ...state.entity,
        loading: false,
        images,
        error: false,
        type: 'development',
        id: action.payload.data.id,
      };

      // the development description comes as plain text
      InstagramPostSlice.caseReducers.setInstagramPostDescription(state, {
        type: 'InstagramPost/setInstagramPostDescription',
        payload: action.payload.data.description,
      });
    });
    builder.addCase(getInstagramDevelopmentData.rejected, (state) => {
      state.entity = { ...state.entity, error: true, loading: false };
    });
    builder.addCase(postInstagramPublication.pending, (state) => {
      state.posting.loading = true;
    });
    builder.addCase(postInstagramPublication.fulfilled, (state) => {
      const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
      const entityUrl = `${tokkoBrokerHost}/${state.entity.type}/${state.entity.id}?instagram_status=1`;
      window.location.href = entityUrl;
    });
    builder.addCase(postInstagramPublication.rejected, (state) => {
      const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
      const entityUrl = `${tokkoBrokerHost}/${state.entity.type}/${state.entity.id}?instagram_status=2`;
      window.location.href = entityUrl;
    });
  },
});

export const {
  setInstagramPostData,
  setInstagramPostDescription,
  setInstagramPostItems,
  handleInstagramPostPrevStep,
  handleInstagramPostNextStep,
} = InstagramPostSlice.actions;

export default InstagramPostSlice.reducer;
